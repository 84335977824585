var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticStyle:{"margin-top":"20px"},attrs:{"data":_vm.data,"border":"","header-cell-style":_vm.headerCellStyle}},[_c('el-table-column',{attrs:{"prop":"func","label":"详细功能"}}),_c('el-table-column',{attrs:{"prop":"leaflet","label":_vm.mobile? 'leaf':'leaflet',"filters":[
    { text: '支持', value: '支持' },
    { text: '后续支持', value: '后续支持'},
     { text: '支持', value: '支持'},
     { text: '无计划', value: '无计划'}],"filter-method":_vm.filterLeafletTag,"filter-placement":"bottom-end"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:['default',{'support': scope.row.leaflet === '支持'}, {'not-support': scope.row.leaflet === '不支持'}]},[_vm._v(_vm._s(scope.row.leaflet === '支持'? '√': scope.row.leaflet === '不支持'? '×': '-'))])]}}])}),_c('el-table-column',{attrs:{"prop":"mapboxgl","label":_vm.mobile? 'map':'MapboxGL',"filters":[
    { text: '支持', value: '支持' },
    { text: '后续支持', value: '后续支持'},
    { text: '支持', value: '支持'},
    { text: '无计划', value: '无计划'}],"filter-method":_vm.filterMapboxTag,"filter-placement":"bottom-end"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:['default',{'support': scope.row.mapboxgl === '支持'}, {'not-support': scope.row.mapboxgl === '不支持'}]},[_vm._v(_vm._s(scope.row.mapboxgl === '支持'? '√': scope.row.mapboxgl === '不支持'? '×': '-'))])]}}])}),_c('el-table-column',{attrs:{"prop":"openlayers","label":_vm.mobile? 'ol':'OpenLayers',"filters":[
    { text: '支持', value: '支持' },
    { text: '后续支持', value: '后续支持'},
    { text: '支持', value: '支持'},
    { text: '无计划', value: '无计划'}],"filter-method":_vm.filterOpenlayerTag,"filter-placement":"bottom-end"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:['default',{'support': scope.row.openlayers === '支持'}, {'not-support': scope.row.openlayers === '不支持'}]},[_vm._v(_vm._s(scope.row.openlayers === '支持'? '√': scope.row.openlayers === '不支持'? '×': '-'))])]}}])}),_c('el-table-column',{attrs:{"prop":"cesium","label":_vm.mobile? 'ce':'Cesium',"filters":[
    { text: '支持', value: '支持' },
    { text: '后续支持', value: '后续支持'},
    { text: '支持', value: '支持'},
    { text: '无计划', value: '无计划'}],"filter-method":_vm.filterCesiumTag,"filter-placement":"bottom-end"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:['default',{'support': scope.row.cesium === '支持'}, {'not-support': scope.row.cesium === '不支持'}]},[_vm._v(_vm._s(scope.row.cesium === '支持'? '√': scope.row.cesium === '不支持'? '×': '-'))])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }